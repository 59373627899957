@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --main-color: #646568;
    --black-color: #121212;
    --border: 0.1rem solid rgba(255, 255, 255, 0.4);
}

* {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    text-decoration: none;
    text-transform: capitalize;
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-padding-top: 9rem;
    scroll-behavior: smooth;
}

body {
    background-color: var(--main-color);
}

section {
    padding: 3.2rem 2%;
}

.main-section {
    background-image: url('../public/assets/iqtestanasayfa.svg'); /* Arka plan resmi */
    display: flex;
    flex-direction: column; /* Elemanları alt alta hizala */
    align-items: flex-start; /* Sol tarafta hizala */
    justify-content: center; /* Sayfada ortala */
    height: 95vh; /* Tam sayfa yüksekliği */
    background-color: #f5f5f5; /* Arka plan rengi */
    background-size: contain; /* Resmi tam sayfa kapla */
    background-position: right; /* Resmi merkeze yerleştir */
    background-repeat: no-repeat; /* Resmi tekrar etme */
    padding-left: 5rem; /* Soldan boşluk */
    padding-bottom: 25rem; /* Üstten boşluk */
    
}
.additional-info {
    font-size: 1.5rem; /* Yazı boyutu */
    color: #333; /* Yazı rengi */
    margin-top: 2rem; /* Üst boşluk */
    text-align: left; /* Soldan hizalama */
}

.additional-info ul {
    list-style-type: none; /* Madde işaretlerini kaldır */
    padding-left: 0; /* Sol boşluğu kaldır */
}

.section-header h3{
    padding-right: 60rem;
    padding-bottom: 75rem;
    font-size: 8rem; /* Güncellendi */
    color: #d99607;
    margin-bottom: -73rem; /* Başlık ile paragraf arasında boşluk */
}

.section-paragraph {
    font-size: 2.5rem;
    padding-right: 60rem;
    margin-bottom: 1rem; /* Paragraf ile buton arasında boşluk */
    color: #333;
}

.main-section .custom-button {
    margin-right: 50rem;
    font-size: 2.5rem;
    padding: 1rem 2rem;
    border-radius: 8px;
}

/* Navbar için stil ekleyin */
.navbar {
    position: relative;
    padding: 20px 20px; 
    background-color: white; 
    border-bottom: 2px solid rgba(223, 154, 7, 0.897); 
    box-shadow: 0 2px 5px ; 
}
.navbar a {
    margin-left: 4rem;
    font-size: 2.5rem;
    margin-right: 18rem;
    padding-top: 2rem;
}

.navbar .nav-link {
    font-size: 1.8rem; 
    margin-right: 3rem; 
    position: relative;
}

.navbar .nav-link::after {
    content: ""; /* İçerik ekle */
    display: block; /* Blok düzende göster */
    width: 100%; /* Tam genişlik */
    height: 2px; /* Çizgi yüksekliği */
    background: #ffc107; /* Çizgi rengi */
    position: absolute; /* Pozisyonu mutlak yap */
    left: 0; /* Sol kenar hizalaması */
    bottom: -5px; /* Alt kenar boşluğu */
    transform: scaleX(0); /* Başlangıçta görünmez */
    transition: transform 0.3s ease; /* Geçiş efekti */
}

.navbar .nav-link:hover::after {
    transform: scaleX(1); /* Üzerine gelindiğinde görünür hale getir */
}

.navbar .custom-button {
    margin-top: 1rem;
    margin-left: 4rem;
    font-size: 2rem;
    width: 25rem;
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}
/*nav improve start*/
.nav-improve {
    height: 120vh;
    background-color: #f0f0f0;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.background-image {
    background-image: url('../public/assets/site2.svg'); /* Arka plan resmi */
    margin-bottom: 10rem;
    background-position: center; /* Resmi ortalar */
    background-repeat: no-repeat; /* Resmi tekrar etme */
    background-size: contain; /* Resmi tamamen kapla, ihtiyaca göre değiştirilebilir */
    width: 80%; /* Boyutu ayarla */
    height: 60%; /* Boyutu ayarla */
    border-radius: 4rem; /* Köşeleri yuvarla */
    overflow: hidden; /* Taşan kısımları gizle */
}

/* Optionally, add text styling inside if needed */
.nav-improve p {
    font-size: 20px;
    color: #333;
}


.info-section {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 20px;
}

.info-item {
    
    font-size: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 30%; /* Ensure three items are evenly spaced */
}

.info-number {
    font-size: 5rem;
    font-weight: bold;
    color: #FF9900; /* Customize as needed */
    margin-bottom: 10px;
}

.info-text {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    color: #333;
    line-height: 1.4;
}
/*nav improve end*/
/* nav-section*/

.nav-section {
    width: 160rem;
    height: 80vh; /* Yükseklik */
    background-color: rgba(223, 154, 7, 0.897); /* Arka plan rengi */
    display: flex; /* Flex kullanarak içeriği hizalama */
    align-items: center; /* Dikey ortalama */
    justify-content: center; /* Yatay ortalama */
    text-align: center; /* Metni merkeze hizala */
    border-top: 2px solid rgba(223, 154, 7, 0.897); /* Üstte bir çerçeve */
    border-radius: 4rem;
    max-height: 400px; /* Yüksekliği sabitleyin */
    overflow-y: auto;  /* Kaydırma çubuğu ekleyin */
    overflow-x: hidden; /* Yatay kaydırmayı engelleyin */
    width: 100%;
    border-bottom: 2px solid yellow;
}



/*nav-section end*/

/*footer start*/

.footer {
    background-image: url('../public/assets/footer.svg'); 
    border-top: 4px solid yellow;
    display: flex;
    background-size: cover;
    background-position: center;
    height: 15vh; /* Yükseklik */
    background-color: #f0f0f0; /* Arka plan rengi */
    color: black; /* Yazı rengi */
    display: flex;
    align-items: center; /* Dikey ortalama */
    justify-content: center; /* Yatay ortalama */
    text-align: center; /* Metni merkeze hizala */
    padding: 20px;
}

.footer-content h5 {
    margin-bottom: 10px; /* Başlık ile metin arasında boşluk */
}

/*footer end*/


/* Medya sorguları */
@media (max-width: 1200px) {
    .section-header h3{
        font-size: 4rem; /* Güncellendi */
        padding-right: 30rem;
    }

    .section-paragraph {
        font-size: 6rem;
        padding-right: 30rem;
    }

    .main-section .custom-button {
        font-size: 10rem;
        margin-right: 30rem;
    }

    .navbar a {
        font-size: 2rem;
        margin-right: 10rem;
    }
}

@media (max-width: 768px) {
    html {
        font-size: 50%; /* Daha küçük ekranlar için daha küçük yazı boyutu */
    }

    .section-header {
        font-size: 6rem; /* Güncellendi */
        padding-right: 10rem;
    }

    .section-paragraph {
        font-size: 2.1rem;
        padding-right: 10rem;
    }

    .main-section .custom-button {
        font-size: 2.1rem;
        margin-right: 10rem;
    }

    .navbar a {
        font-size: 1.8rem;
        margin-right: 5rem;
    }
}

@media (max-width: 480px) {
    .section-header {
        font-size: 4rem; /* Güncellendi */
        padding-right: 5rem;
    }

    .section-paragraph {
        font-size: 1.5rem;
        padding-right: 5rem;
    }

    .main-section .custom-button {
        font-size: 1.5rem;
        margin-right: 5rem;
        padding: 0.5rem 1rem; /* Butonun iç boşluklarını azalt */
    }

    .navbar a {
        font-size: 1.5rem;
        margin-right: 2rem;
    }
}
