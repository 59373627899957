@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --main-color: #646568;
    --black-color: #121212;
    --border: 0.1rem solid rgba(255, 255, 255, 0.4);
}

* {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    text-decoration: none;
    text-transform: capitalize;
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-padding-top: 9rem;
    scroll-behavior: smooth;
}

body {
    background-color: white;
}

section {
    padding: 3.2rem 2%;
}


.navbar {
    margin-bottom: 0.4rem;
    position: relative;
    padding: 0.7rem 30px; 
    background-color: white; 
    border-bottom: 2px solid rgba(223, 154, 7, 0.897); 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
}
.navbar a {
    margin-left: 4rem;
    font-size: 2.5rem;
    margin-right: 18rem;
    padding-top: 2rem;
}

.navbar .nav-link {
    font-size: 1.8rem; 
    margin-right: 3rem; 
    position: relative;
}

.navbar .nav-link::after {
    content: ""; /* İçerik ekle */
    display: block; /* Blok düzende göster */
    width: 100%; /* Tam genişlik */
    height: 2px; /* Çizgi yüksekliği */
    background: #ffc107; /* Çizgi rengi */
    position: absolute; /* Pozisyonu mutlak yap */
    left: 0; /* Sol kenar hizalaması */
    bottom: -5px; /* Alt kenar boşluğu */
    transform: scaleX(0); /* Başlangıçta görünmez */
    transition: transform 0.3s ease; /* Geçiş efekti */
}

.navbar .nav-link:hover::after {
    transform: scaleX(1); /* Üzerine gelindiğinde görünür hale getir */
}

.navbar .custom-button {
    margin-top: 1rem;
    margin-left: 4rem;
    font-size: 2rem;
    width: 25rem;
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}
/* nav-section*/

.nav-section {
    width: 160rem;
    height: 80vh; /* Yükseklik */
    background-color: rgba(223, 154, 7, 0.897); /* Arka plan rengi */
    display: flex; /* Flex kullanarak içeriği hizalama */
    align-items: center; /* Dikey ortalama */
    justify-content: center; /* Yatay ortalama */
    text-align: center; /* Metni merkeze hizala */
    border-top: 2px solid rgba(223, 154, 7, 0.897); /* Üstte bir çerçeve */
    border-radius: 1rem;
    max-height: 400px; /* Yüksekliği sabitleyin */
    overflow-y: hidden;  /* Kaydırma çubuğu ekleyin */
    overflow-x: hidden; /* Yatay kaydırmayı engelleyin */
    width: 100%;
}

/*test section*/
.test-main {
    
    height: 90vh; /* Yüksekliği 100vh yap */
    display: flex; /* Flexbox kullanarak hizalama */
    flex-direction: column; /* Dikey hizalama */
    background-color: white;
}

.test-main-content {
    display: flex; /* Flexbox kullanarak hizalama */
    flex: 1; /* Yüksekliği alacak şekilde ayarla */
}

.test-main-content .left-section,
.test-main-content .right-section {
    flex: 1; /* Her iki bölümün eşit genişlikte olmasını sağlar */
    display: flex; /* Flexbox kullanarak hizalama */
    flex-direction: column; /* Dikey hizalama */
    
}

.test-main-content .left-section {
    background-color: #f0f0f0; /* Sol bölüm için arka plan rengi */
   
}

.test-main-content .right-section {
    background-color: #e0e0e0; /* Sağ bölüm için arka plan rengi */
    
}

.test-main-content .section {
    flex: 1; /* Her bir bölümün eşit yükseklikte olmasını sağlar */
    margin: 10px; /* Bölümler arasında boşluk */
    border: 1px solid #ccc; /* Kenarlık */
    border-radius: 5px; /* Köşe yuvarlama */
    padding: 20px; /* İç boşluk */
}
.top-section{
     /* Alt boşluk ekleyin */
    
    height: 8vh; /* Yükseklik 20vh */
    margin: 0 auto;
    padding: 3rem;
    border-radius: 3rem;
    background-color: #f4c542; /* Sarı renk */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; /* Yazı rengi */
    font-size: 1.5rem; /* Yazı büyüklüğü */
    width: 90%;
}
.bottom-section {
    height: 10vh; /* Yükseklik 20vh */
    margin: 0 auto;
    padding: 3rem;
    border-radius: 3rem;
    background-color: #f4c542; /* Sarı renk */
    display: flex;
    align-items: center;
    justify-content: center;
    color: black; /* Yazı rengi */
    font-size: 1.5rem; /* Yazı büyüklüğü */
    width: 90%;
    margin-bottom: 1rem;
}
/*timer start*/
.top-section {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: yellow; 
    color: black;
    padding: 10px;
}

.timer-icon {
    width: 30px; /* İkon boyutu */
    height: 30px;
    margin-right: 10px; /* Sayı ile ikon arasına boşluk */
}

.timer-text {
    font-size: 2rem; /* Süre metni boyutu */
    font-weight: bold;
    color: black;
}
/*timer end*/

/*test section end*/
